import { Injectable } from '@angular/core';
import { makeAutoObservable, toJS } from 'mobx';
import { OrganizationLevelService } from '../../features/settings/general-settings/services/organization-level.service';
import { OrganizationLevelDetailsDTO, OrganizationLevelDTO } from '../../features/settings/general-settings/dto/organization-level.dto';
import { map, Observable } from 'rxjs';
import { OrganizationLevelConversion } from '../conversion/organization-level.conversion';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsStore {
  totalLevels: number = 0;
  primaryLevel: OrganizationLevelDTO | null = null;
  levels: OrganizationLevelDetailsDTO[] = [];
  dateFormat: string = 'MMM D, YYYY';
  dateTimeFormat: string = 'MMM D, YYYY h:mm A';
  datePickerFormat: string = 'Y-m-d';
  dateTimePickerFormat: string = 'Y-m-d\\TH:i:S';
  timezone: string = 'Asia/Dubai';

  constructor(private organizationLevelService: OrganizationLevelService, private organizationLevelConversion:OrganizationLevelConversion) {
    makeAutoObservable(this);
  }

  loadOrganizationLevels(): Observable<OrganizationLevelDTO[]> {
    return this.organizationLevelService.getOrganizationLevels();
  }

  initialize(): Observable<void> {
    return this.loadOrganizationLevels().pipe(
      map((data) => {
        this.levels = this.organizationLevelConversion.resToDetails(data);;
        this.primaryLevel = data.find((level) => level.is_primary) || null;
        this.totalLevels = Math.max(...data.map((item) => item.level));
      })
    );
  }

  get primaryLevelData() {
    return toJS(this.primaryLevel);
  }

  get organizationLevels() {
    return toJS(this.levels);
  }
}
