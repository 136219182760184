import { Injectable } from '@angular/core';
import { OrganizationLevelDetailsDTO } from '../../features/settings/general-settings/dto/organization-level.dto';
import { CurrentUserPreferenceStore } from '../store/current-user-preference.store';

@Injectable({
  providedIn: 'root',
})
export class OrganizationLevelConversion {
  private lang: string;

  constructor(private currentUserPreferenceStore: CurrentUserPreferenceStore) 
  {
    this.lang = currentUserPreferenceStore.languageKey;
  }

  resToDetails(response: any[]): OrganizationLevelDetailsDTO[] {

    if (!response) {
      return [];
    }
    const result = response.map((item) => {
      return {
        id: item.id,
        level: item.level,
        is_primary: item.is_primary,
        title: item['title_' + this.lang],
      };
    });
    return result;
  }

}
